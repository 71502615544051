import { Modal, Typography, Box, Divider, Button } from '@mui/material';
import { CourseCardConstants } from '../../../constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import Styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { VariantButton, VariantTypography } from '../../../themes/properties';

import { showRenewMembershipModal } from '../redux/dashboardSlice';
import { openPage } from '../../../utils/utils';

const RenewMembershipModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handleClose = () => {
    dispatch(showRenewMembershipModal(false));
  };

  const handleRenewMembership = () => {
    openPage(CourseCardConstants.expiredMembership.membershipLink, false);
    dispatch(showRenewMembershipModal(false));
  };

  const header = (
    <Box sx={Styles.boxHeader}>
      <FontAwesomeIcon icon={faPaperPlane} />
      <Box sx={Styles.boxHeaderText}>
        <Typography sx={Styles.textHeader}>
          {CourseCardConstants.expiredMembership.header}
        </Typography>
      </Box>
      <Box sx={Styles.iconClose} onClick={handleClose}>
        <FontAwesomeIcon icon={faXmark} />
      </Box>
    </Box>
  );

  const bottomView = (
    <Box sx={Styles.rootBottom}>
      <Divider />
      <Box sx={Styles.boxContainerBottomView}>
        <Button
          variant={VariantButton.contained}
          sx={Styles.button}
          onClick={handleRenewMembership}
        >
          {CourseCardConstants.expiredMembership.buttons.renewMembership}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal open={true} sx={Styles.modal} onClose={handleClose}>
      <Box sx={Styles.root}>
        <>{header}</>

        <Box sx={Styles.boxSubHeader}>
          <Typography variant={VariantTypography.h6} sx={Styles.txtSubHeader}>
            {CourseCardConstants.expiredMembership.subHeader}
          </Typography>
        </Box>

        <Box sx={Styles.boxContent}>
          <Typography variant={VariantTypography.h6} sx={Styles.txtMessage}>
            {CourseCardConstants.expiredMembership.message}
          </Typography>
        </Box>
        <>{bottomView}</>
      </Box>
    </Modal>
  );
};

export default RenewMembershipModal;

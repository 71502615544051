import { Typography } from '@mui/material';
import { APP_ENV, profileHeaderConstants } from '../../constants';
import Styles from './RenewMembershipText.style';

const RenewMembershipText = () => {
  let sku: string;
  switch (APP_ENV) {
    case 'production':
      sku = 'SU-01-STR-DAY-030-ST';
      break;
    case 'staging':
      sku = 'SU-01-STR-DAY-007-ST';
      break;
    default: // INT
      sku = 'SU-01-STR-DAY-030-ST';
  }

  return (
    <Typography
      sx={Styles.text}
      dangerouslySetInnerHTML={{
        __html: profileHeaderConstants.renewMembership.replace('%s', sku),
      }}
    />
  );
};

export default RenewMembershipText;

import { Urls } from '../../../constants';
import { ContentType, interfaceType } from '../types';

export const Refunds: ContentType = {
  header: 'Refunds',
  subHeader: 'Effective as of January 1, 2024',
  sections: [
    {
      title: '',
      sections: [
        `The following Refund Policy applies to all paid Company Products, including StraighterLine Memberships, Course Enrollment Fees, Test Purchases, eTextbook Purchases and Proctoring Purchases.`,
        `IMPORTANT NOTE: You will not be entitled to any refund if a StraighterLine Membership or other Company Product has been canceled or is no longer accessible due to a violation of <a href="/help-center/student-handbook/terms-of-use/" title="StraighterLine's Terms of Use">StraighterLine’s Terms of Use.</a>`,
      ],
    },
    {
      title: 'StraighterLine Memberships',
      sections: [
        `Monthly Membership fees are non-refundable and are charged automatically on each renewal date (i.e. monthly) until you cancel. You may cancel at any time during the month. Upon cancelation, your Membership will expire 30 days from the last payment. Any amounts paid prior to your cancellation are not refundable. To cancel your Membership, sign into your MyLine account on the site and go to <a href="${Urls.BILLING_PREFERENCE}" title="Billing Preferences">Billing Preferences</a>. Our Student Advisors are also available to assist. Please contact our Student Advisors at <a href="tel:8443977501">(844) 397-7501</a>, Live Chat or <a href="${window.location.origin}/help-center/support-request" title="create a Support Request">create a Support Request</a>. Student Support Hours: Monday - Friday, 8:00am-8:00pm, Saturday - Sunday, 9:30am - 5:00pm EST.`,
      ],
    },
    {
      title: 'Additional Notes Regarding Memberships Fees',
      sections: [
        `StraighterLine Monthly Memberships automatically renew each month, at which time your original payment method will be charged $99. Monthly charges are billed on the same day you originally enrolled and may take a few days after the billing date to appear on your account. Please cancel or make payment changes at least one day prior to your next billing date to ensure your account is not charged for another billing cycle.`,
      ],
    },
    {
      title: 'Courses',
      sections: [
        `StraighterLine Course Enrollment Fees are non-refundable. However, if you’ve purchased a course in error and need to purchase a different course, you may request a course change. Please contact our Student Advisors at <a href="tel:8443977501">(844) 397-7501</a> , Live Chat or <a href="${window.location.origin}/help-center/support-request" title="create a Support Request">create a Support Request</a>. Student Support Hours: Monday - Friday, 8:00am-8:00pm, Saturday - Sunday, 9:30am - 5:00pm EST.`,
      ],
    },
    {
      title: 'Modification',
      sections: [
        `StraighterLine reserves the right to revise the terms of this Refund Policy. Any changes made will apply to all Memberships created or renewed after the date such change was implemented.`,
      ],
    },
  ],
};

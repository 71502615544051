import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material';
import Styles from './RightPanel.style';
import Profile from '../../models/Profile.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { isDateExpired } from '../../utils/dateUtils';
import {
  profileHeaderConstants,
  dashboardRightPanel,
  screenBreakpoints,
  partnerCollegeLogoUrl,
  dataTestIds,
} from '../../constants';
import Institute from '../../models/Institute.model';
import { VariantTypography } from '../../themes/properties';
import HelpfulLinks from './helpful-links/HelpfulLinks.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import RenewMembershipText from '../renew-membership/RenewMembershipText.component';
import PurchaseCourseToday from './purchase-a-course-today/PurchaseCourseToday.component';
import CourseRecommendation from './recommendation-section/CourseRecommendation.component';

interface PropsType {
  onClose?: () => void;
  isOpen?: boolean;
  isUserFreeTrial: boolean;
}

const RightPanel = ({ onClose, isOpen, isUserFreeTrial }: PropsType) => {
  const screenPhone = useMediaQuery(`(max-width:${screenBreakpoints.phone})`);

  const profile: Profile | null = useSelector(
    (state: RootState) => state.profile.profile,
  );

  const institutes: Array<Institute> | null = useSelector(
    (state: RootState) => state.app.institutes,
  );

  const institute: Institute | null = useSelector(
    (state: RootState) => state.app.targetInstitute,
  );

  const isMembershipExpired = isDateExpired(profile?.membershipExpire ?? '');

  const membershipExpiredView = (
    <Box
      sx={Styles.txtMembershipName}
      data-testid={dataTestIds.rightPanel.expiredMembership}
    >
      <RenewMembershipText />
    </Box>
  );

  const targetCollegeView = (
    <Box>
      <Typography
        variant={VariantTypography.body2}
        sx={Styles.textMySchool}
        data-testid={dataTestIds.rightPanel.myTargetSchool}
      >
        {dashboardRightPanel.myTargetSchool}
      </Typography>
      {!profile?.isCollegeUndecided ? (
        institute?.isPartner && institute.uri ? (
          <CardMedia
            component="img"
            sx={Styles.partnerLogo}
            image={partnerCollegeLogoUrl.replaceAll('%s1', institute.uri)}
            title={institute.name}
            data-testid={dataTestIds.rightPanel.myTargetSchoolMedia}
          ></CardMedia>
        ) : (
          <Typography
            variant={VariantTypography.body2}
            sx={Styles.textCollege}
            data-testid={dataTestIds.rightPanel.myTargetSchoolValue}
          >
            {institute?.name ?? profileHeaderConstants.undecidedCollege}
          </Typography>
        )
      ) : (
        <Typography
          variant={VariantTypography.body2}
          sx={Styles.textCollege}
          data-testid={dataTestIds.rightPanel.undecidedCollege}
        >
          {profileHeaderConstants.undecidedCollege}
        </Typography>
      )}
    </Box>
  );
  const closeIcon = (
    <Box
      sx={Styles.boxCloseIcon}
      onClick={onClose}
      data-testid={dataTestIds.rightPanel.closeIcon}
    >
      <FontAwesomeIcon icon={faXmark} />
    </Box>
  );
  return (
    <Box sx={Styles.root}>
      {screenPhone && isOpen && closeIcon}
      <Box sx={Styles.container}>
        {isMembershipExpired && membershipExpiredView}
        {!isUserFreeTrial && targetCollegeView}
        <HelpfulLinks />
        {isUserFreeTrial && <PurchaseCourseToday />}
        {!isUserFreeTrial && <CourseRecommendation />}
      </Box>
    </Box>
  );
};

export default RightPanel;

import { themeColors } from '../../themes/colors';
import { spacing } from '../../themes/theme';

const Styles = {
  text: {
    textTransform: 'capitalised',
    fontWeight: 400,
    fontSize: `${spacing * 1.75}px`,
    color: themeColors.tertiaryBlack,
    '& a': {
      textDecoration: 'none',
      color: themeColors.darkBlue,
    },
  },
};

export default Styles;

import { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  FormControl,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Styles from './LeftAccountPanel.style';
import { accountMenu, AccountType } from './Account.types';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AccountSettingsConstants,
  Routes,
  profileHeaderConstants,
  screenBreakpoints,
} from '../../constants';
import { VariantDivider } from '../../themes/properties';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Profile from '../../models/Profile.model';
import { formatData, dateFormats, isDateExpired } from '../../utils/dateUtils';
import RenewMembershipText from '../../common-components/renew-membership/RenewMembershipText.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { openPage } from '../../utils/utils';
import { LearnerType } from '../../types/global';

const LeftAccountPanel = () => {
  const profile: Profile | null = useSelector(
    (state: RootState) => state.profile.profile,
  );
  const [selectedItem, setSelectedItem] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleItemClick = (item: AccountType) => {
    if (item?.route) {
      navigate(item?.route);
      setSelectedItem(item.title);
    } else if (item?.url) {
      openPage(item.url, false);
    }
  };

  const screenLaptopLarge = useMediaQuery(
    `(max-width:${screenBreakpoints.laptopLarge})`,
  );

  const handleMenuChange = (e: any) => {
    const selectedValue = e.target.value as string;
    const selectedItem: AccountType | null =
      accountMenu.find((item: AccountType) => item.title === selectedValue) ??
      null;
    if (selectedItem) {
      handleItemClick(selectedItem);
    }
  };

  const menuItem = accountMenu
    .filter((item: AccountType) => {
      return !(
        (!profile?.membershipExpire ||
          profile?.learnerType === LearnerType.DIRECT_ENROLLMENT) &&
        item.title === AccountSettingsConstants.billingPreferences
      );
    })
    .map((item: AccountType) => {
      const isSelected: boolean = item.title === selectedItem;
      return (
        <Box key={item.title}>
          <ListItemButton
            onClick={() => handleItemClick(item)}
            sx={Styles.itemContainer}
            disableTouchRipple
          >
            {isSelected && (
              <ListItemIcon sx={Styles.expandIcon}>
                <FontAwesomeIcon icon={faPlay} />
              </ListItemIcon>
            )}
            <Box sx={Styles.boxMenu}>
              <ListItemText
                primary={item.title}
                sx={isSelected ? Styles.selectedListItem : Styles.listItemText}
              />

              {item.icon && (
                <Box sx={Styles.boxMenuIcon}>
                  <FontAwesomeIcon icon={item.icon} />
                </Box>
              )}
            </Box>
          </ListItemButton>
        </Box>
      );
    });

  const dropDown = (
    <FormControl size="small" sx={Styles.formControl}>
      <Select
        value={selectedItem}
        onChange={(e) => handleMenuChange(e)}
        sx={Styles.menuContainer}
      >
        {accountMenu
          .filter((item: AccountType) => {
            return !(
              (!profile?.membershipExpire ||
                profile?.learnerType === LearnerType.DIRECT_ENROLLMENT) &&
              item.title === AccountSettingsConstants.billingPreferences
            );
          })
          .map((item: AccountType) => (
            <MenuItem
              value={item.title}
              key={item.title}
              selected={item.title === selectedItem}
              sx={Styles.menuItem}
            >
              {item.title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );

  useEffect(() => {
    // Update the selected item based on the current route
    const url = location.pathname;
    const currentPath = url.split(Routes.home);
    const menuPath = currentPath.length > 1 ? currentPath[2] : null;
    if (menuPath) {
      for (let index = 0; index < accountMenu.length; index++) {
        const card: AccountType = accountMenu[index];
        if (card?.route?.includes(menuPath)) {
          setSelectedItem(card.title);
          break;
        }
      }
    }
  }, [location.pathname]);

  const isMembershipExpired = isDateExpired(profile?.membershipExpire ?? '');

  const dateView = !profile?.membershipExpire ? null : isMembershipExpired ? (
    <Box sx={Styles.navigateBackText}>
      <RenewMembershipText />
    </Box>
  ) : (
    <>
      <Typography sx={Styles.navigateBackText}>
        {profileHeaderConstants.membershipCurrent}
      </Typography>
      <Typography sx={Styles.txtMembershipValue}>
        {formatData(profile?.membershipExpire, dateFormats.MMMMdYYYY)}
      </Typography>
      <Divider sx={Styles.divider} variant={VariantDivider.middle} />
    </>
  );

  return (
    <>
      {!screenLaptopLarge ? dateView : null}
      <List sx={Styles.leftPanelList}>
        {!screenLaptopLarge ? menuItem : dropDown}
      </List>
    </>
  );
};

export default LeftAccountPanel;

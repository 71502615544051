import axiosInstance from '../../../http-requests/httpClient';
import {
  APIs,
  GlobalConstants,
  completeCourseImageBannerUrl,
  courseProvider,
  EMPTY_STRING,
} from '../../../constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICE_DASHBOARD } from '../../../store/slices';
import Course, { CourseSection } from '../../../models/Course.model';
import Assignment from '../../../models/Assignment.model';
import Topic from '../../../models/Topic.model';
import { CourseStatus } from '../../../types/global';
import {
  GetCourseListResponse,
  CourseResponseType,
  CourseSectionResponseType,
  GetAssignmentListResponse,
  AssignmentResponseType,
  ErrorResponse,
  GetLmsProviderDetailsResponse,
  EnrollFreeTrialCourseType,
  AcrobatiqLoginParams,
} from '../../../types/contract';
import { RootState } from '../../../store';
import { showToast } from '../../../store/app.slice';
import { getMasterData } from '../../../utils/auth.util';
import Profile from '../../../models/Profile.model';
import { openPage } from '../../../utils/utils';
import { getProfile as LocalStorageProfile } from '../../../utils/auth.util';
import { SnackbarAnchorSeverity } from '../../../themes/properties';
import { moodleFormHtml } from '../../../utils/moodle.util';
import { updateCourse } from './dashboardSlice';

export const getCourseList = createAsyncThunk(
  `${SLICE_DASHBOARD + APIs.getCourses}`,
  async (_, thunkAPI) => {
    const { auth }: RootState = thunkAPI.getState() as RootState;
    let courseIdentifiers: Array<string> = [];
    try {
      const profile = LocalStorageProfile();
      const response = await axiosInstance.get(
        APIs.getCourses.replace('%s', profile?.id ?? ''),
      );
      const responseData: GetCourseListResponse = response?.data ?? {};
      const courses: Array<Course> = prepareCourseList(responseData);
      courseIdentifiers = courses.map(
        (course: Course) => course.courseIdentifier,
      );
      thunkAPI.dispatch(
        courseRecommendation({
          courseIdentifier: courseIdentifiers.filter((c) => c !== null),
        }),
      );
      return courses;
    } catch (e: any) {
      // Do not show error message if no courses returned
      // TODO: rework back-end, to return an empty array instead of 404 error, when no courses found
      // https://straighterline.atlassian.net/browse/LA-3101?focusedCommentId=189960
      if (e?.response?.status !== 404) {
        const error: ErrorResponse = e?.response?.data as ErrorResponse;
        thunkAPI.dispatch(
          showToast({
            show: true,
            message: error?.friendlyMessage ?? error.message,
            severity: SnackbarAnchorSeverity.error,
          }),
        );
      }

      thunkAPI.dispatch(
        courseRecommendation({
          courseIdentifier: [],
        }),
      );
    }
  },
);

const getAssignmentsAPI = async (id: string, courseIdentifier: string) => {
  const assignmentResponse = await axiosInstance.get(
    APIs.getAssignments.replace('%s1', id).replace('%s2', courseIdentifier),
  );
  const completeAssignmentData: GetAssignmentListResponse =
    assignmentResponse?.data ?? {};
  return completeAssignmentData;
};

export const getAssignmentList = createAsyncThunk(
  `${SLICE_DASHBOARD + APIs.getAssignments}`,
  async (courseIdentifier: string, thunkAPI) => {
    const { auth, dashboard }: RootState = thunkAPI.getState() as RootState;
    try {
      const profile = LocalStorageProfile();
      const responseData = await getAssignmentsAPI(
        profile?.id ?? '',
        courseIdentifier,
      );

      const topics = prepareAssignmentList(
        responseData,
        courseIdentifier,
        dashboard.courses,
      );
      return { topics, courseIdentifier: courseIdentifier };
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error?.friendlyMessage ?? error.message,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const getLMSProviderDetails = createAsyncThunk(
  `${SLICE_DASHBOARD + APIs.getLMSProviderDetails}`,
  async (
    {
      courseIdentifier,
      linkId,
      resume,
    }: { courseIdentifier: string; linkId: string; resume: boolean },
    thunkAPI,
  ) => {
    try {
      const getDetails = async () => {
        const profile = LocalStorageProfile();
        return axiosInstance.post(
          APIs.getLMSProviderDetails
            .replace('%s1', profile?.id ?? '')
            .replace('%s2', courseIdentifier),
          {
            provider: courseProvider,
            sectionId: linkId,
            resume: resume,
          },
        );
      };

      let userAgentString = navigator.userAgent;
      // Detect Chrome
      let chromeAgent = userAgentString.indexOf('Chrome') > -1;
      // Detect Safari
      let safariAgent = userAgentString.indexOf('Safari') > -1;
      // Discard Safari since it also matches Chrome
      if (chromeAgent && safariAgent) safariAgent = false;

      let popupWindow: Window | null;
      /*
       Trying the following workaround approach for Safari, to prevent blocking opening a new tab: https://stackoverflow.com/a/39387533
       1. Open window
       2. Make async request
       */
      if (safariAgent) {
        console.log('Is Safari browser');
        popupWindow = window.open('', '_blank');
        try {
          const lmsProviderDetailsResponse = await getDetails();
          const formHtml = moodleFormHtml(lmsProviderDetailsResponse.data);
          popupWindow?.document.write(formHtml);
        } catch (e: any) {
          popupWindow?.close();
        }
      } else {
        console.log('Is not Safari browser');
        const lmsProviderDetailsResponse = await getDetails();
        const formHtml = moodleFormHtml(lmsProviderDetailsResponse.data);
        popupWindow = window.open('', '_blank');
        popupWindow?.document.write(formHtml);
      }
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const addTrialCourses = createAsyncThunk(
  `${SLICE_DASHBOARD + APIs.addTrialCourses}`,
  async (courses: EnrollFreeTrialCourseType[], thunkAPI) => {
    const { profile }: RootState = thunkAPI.getState() as RootState;
    try {
      const response = await axiosInstance.post(
        APIs.addTrialCourses.replace(
          '%s1',
          profile.profile?.id ?? EMPTY_STRING,
        ),
        { courses },
      );
      thunkAPI.dispatch(getCourseList());
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const takeCourseSurvey = createAsyncThunk(
  `${SLICE_DASHBOARD + APIs.courseSurvey}`,
  async (
    { course, courseIdentifier }: { course: Course; courseIdentifier: string },
    thunkAPI,
  ) => {
    const { profile }: RootState = thunkAPI.getState() as RootState;
    try {
      const response = await axiosInstance.post(
        APIs.courseSurvey.replace('%s', profile.profile?.id ?? EMPTY_STRING),
        {
          type: 'course',
          model: {
            courseEnrolmentId: course.id.toString(),
            courseIdentifier: courseIdentifier,
          },
        },
      );
      if (response.data) {
        course.isSurveyTaken = true;
        const updatedCourse: Course = JSON.parse(
          JSON.stringify(course),
        ) as Course;
        thunkAPI.dispatch(updateCourse(updatedCourse));
      }
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const courseRecommendation = createAsyncThunk(
  `${SLICE_DASHBOARD + APIs.purchaseRecommendation}`,
  async (
    { courseIdentifier }: { courseIdentifier: Array<String> },
    thunkAPI,
  ) => {
    try {
      const response = await axiosInstance.post(APIs.purchaseRecommendation, {
        type: 'course',
        model: {
          excludeCourseIds: courseIdentifier,
        },
      });
      return response.data;
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

export const proctoredExamUrl = createAsyncThunk(
  `${SLICE_DASHBOARD + APIs.getLMSProviderDetails}`,
  async (course: Course, thunkAPI) => {
    try {
      const profile = LocalStorageProfile();
      const lmsProviderDetailsResponse = await axiosInstance.post(
        APIs.getLMSProviderDetails
          .replace('%s1', profile?.id ?? '')
          .replace('%s2', course.courseIdentifier),
        {
          provider: 'proctoru',
          lastName: profile?.lastName,
          firstName: profile?.firstName,
          email: profile?.email,
          returnUrl: window.location.origin,
        },
      );
      if (lmsProviderDetailsResponse.data.IsSuccess)
        openPage(lmsProviderDetailsResponse?.data?.Data?.Url, true);
      else {
        thunkAPI.dispatch(
          showToast({
            show: true,
            message: GlobalConstants.SOMETHING_WENT_WRONG,
            severity: SnackbarAnchorSeverity.error,
          }),
        );
      }
    } catch (e: any) {
      const error: ErrorResponse = e?.response?.data as ErrorResponse;
      thunkAPI.dispatch(
        showToast({
          show: true,
          message: error.friendlyMessage,
          severity: SnackbarAnchorSeverity.error,
        }),
      );
    }
  },
);

const prepareCourseList = (responseData: GetCourseListResponse) => {
  const courses: Array<Course> = [];

  responseData.Courses.forEach((courseData: CourseResponseType) => {
    let progress =
      (courseData.GradeableAssignmentsCompletedCount * 100) /
      courseData.GradeableAssignmentsCount;

    if (!progress) {
      progress = 3;
    }

    let status: string = CourseStatus.Complete;
    if (courseData.GradeableAssignmentsCompletedCount === 0) {
      status = CourseStatus.NotStarted;
    } else if (
      courseData.GradeableAssignmentsCount >
      courseData.GradeableAssignmentsCompletedCount
    ) {
      status = CourseStatus.Progress;
    }

    const sections = courseData.Sections.filter((c) => !!c.Name).map(
      (section: CourseSectionResponseType) =>
        new CourseSection(
          section.Name,
          section.Url,
          section.ResourceLinkId,
          section.CwpCode,
        ),
    );

    let grade: string | null = null;
    let upNext: CourseSection = sections[0];
    if (status === CourseStatus.Complete) {
      grade = 'Final Grade';
      upNext = new CourseSection(
        'Request Transcript',
        'https://www.google.com/',
        '',
        '',
      );
    } else if (status === CourseStatus.Progress) {
      grade = 'Current Grade';
    }
    const courseImageBanner = completeCourseImageBannerUrl
      .replace('%s1', courseData.Code)
      .replace('%s2', courseData.Code)
      .replaceAll('+', '%2B');
    const course = new Course(
      courseData.Id,
      courseData.Name,
      courseImageBanner,
      courseData.Code,
      courseData.CourseIdentifier,
      progress,
      grade,
      upNext,
      status,
      courseData.EnrollmentStatus,
      courseData.Grade,
      courseData.IsPlagiarismViolation,
      courseData.ProctorUrl,
      courseData.MagentoCourseNumber,
      courseData.StartDate,
      courseData.ResourceLinkId,
      courseData.SyllabusUri,
      courseData.isSurveyTaken,
      courseData.CompletionDate,
      courseData.isRetake,
      sections,
      courseData.ProctorProviderCode,
      courseData.CwpCode,
      courseData.CourseUrl,
      courseData.ResumeUrl,
      courseData.ProviderCourseId,
      courseData.ProviderCourseName,
      courseData.IsActive,
      courseData.ExpirationDate,
    );
    courses.push(course);
  });
  return courses;
};

const prepareAssignmentList = (
  responseData: GetAssignmentListResponse,
  id: string,
  courses: Array<Course>,
) => {
  let course: Course | undefined = courses.find(
    (item: Course) => item.courseIdentifier === id,
  );
  let topics: Array<Topic> = [];
  course?.sections.forEach((section: CourseSection, index: number) => {
    const respnoseAssignments = responseData.Assignments.filter(
      (assignment: AssignmentResponseType) =>
        assignment.SectionName === section.name,
    );
    const isComplete = respnoseAssignments?.length
      ? respnoseAssignments.every(
          (assignment: AssignmentResponseType) => !!assignment.IsComplete,
        )
      : false;

    const assignments: Array<Assignment> = respnoseAssignments.map(
      (assignment: AssignmentResponseType) =>
        new Assignment(
          assignment.Id,
          assignment.Name,
          assignment.IsComplete,
          assignment.Grade,
          assignment.SortOrder,
          section.url,
          id,
        ),
    );

    const sortedAssignments: Array<Assignment> = assignments.sort(
      (a: Assignment, b: Assignment) => a.sortOrder - b.sortOrder,
    );
    topics.push(
      new Topic(
        section.name,
        section.url,
        section.resourceLinkId,
        course?.courseIdentifier ?? '',
        sortedAssignments,
        isComplete,
        section.cwpCode,
      ),
    );
  });
  return topics;
};

const getFinalAssignment = async (id: string, courseIdentifier: string) => {
  const assignmentList: GetAssignmentListResponse = await getAssignmentsAPI(
    id,
    courseIdentifier,
  );
  const assignments: AssignmentResponseType[] = assignmentList.Assignments;

  assignments.sort((a: AssignmentResponseType, b: AssignmentResponseType) => {
    return a.SortOrder - b.SortOrder;
  });
  const finalAssignment = assignments[assignments.length - 1];
  return finalAssignment;
};

const getCountryAndStateCodeFromMasterData = async (
  profile: Profile | null,
) => {
  const allMasterData = getMasterData();
  const countryMasterData = allMasterData?.countries;
  const country = countryMasterData?.find(
    (country) => country.id == profile?.address?.country,
  );
  const state = country?.states.find(
    (state) => state.id == profile?.address?.state,
  );
  const countryCode = country?.code;
  const stateCode = state?.code;
  return { countryCode, stateCode };
};
